import { DenmSourceFilter, DenmStatusFilter } from '_store/denm/denmsTypes';
import { IvimStatusFilter } from '_store/ivim/ivimsTypes';

export const enum Method {
  DELETE = 'DELETE',
  GET = 'GET',
  PATCH = 'PATCH',
  POST = 'POST',
  PUT = 'PUT',
}

export const enum DistributionKind {
  UDP = 'udp',
  TEST = 'test',
}

export interface UDPDistributionKind {
  kind: DistributionKind.UDP;
  host: string;
  port: number;
}

export interface RSU {
  location: GeoJsonPoint;
  distribution: UDPDistributionKind;
  name: string;
  roadSegmentId: string;
  id: string;
  slug: string;
  stationId: string;
  direction?: string;
  type?: string;
  km?: string;
  alive?: boolean;
}

export interface NewRSU {
  location: GeoJsonPoint;
  distribution: UDPDistributionKind;
  name: string;
  roadSegmentId: string;
  stationId: string;
  id?: string;
  direction?: string;
  type?: string;
  km?: string;
  alive?: boolean;
}

export interface TVCC {
  idcamera: number;
  agentid: number;
  evtstatus: number;
  node: number;
  dir: number;
  linkid: number;
  cause: number;
  subcause: number;
  rsutarget: string | number;
  frequencybroadcasting: string | number;
  temporallimit: number;
  historyZoneSize: number;
  traceZoneSize: number;
}

export type Coordinate = [number, number];

export interface NodeLoc {
  loc: {
    coordinates: Coordinate;
  };
}

export interface MapNode extends NodeLoc {
  number: number;
  direction: number;
  lane: number;
  link: number;
  country: string;
  distance: number;
}

export interface CAM {
  idcam: number;
  generationTime: string;
  generationTimeNumber: number;
  stationtype: number;
  nodenumber: number;
  dir: 1 | -1 | '1' | '-1';
  speed: number;
  linkid: number;
  date: Date;
  loc: Coordinate;
  stationID: string;
}

export type ActionResult = { ok: true } | { ok: false; error: string };

export interface GeoJson {
  type: string;
  bbox?: number[];
  coordinates: Coordinate | Coordinate[];
}
export interface GeoJsonMultiLineString {
  type: string;
  bbox?: number[];
  coordinates: Coordinate[][];
}
export interface GeoJsonPoint {
  type: 'Point';
  coordinates: Coordinate;
}

export enum AltitudeConfidence {
  alt00001 = 'alt-000-01',
  alt00002 = 'alt-000-02',
  alt00005 = 'alt-000-05',
  alt00010 = 'alt-000-10',
  alt00020 = 'alt-000-20',
  alt00050 = 'alt-000-50',
  alt00100 = 'alt-001-00',
  alt00200 = 'alt-002-00',
  alt00500 = 'alt-005-00',
  alt01000 = 'alt-010-00',
  alt02000 = 'alt-020-00',
  alt05000 = 'alt-050-00',
  alt10000 = 'alt-100-00',
  alt20000 = 'alt-200-00',
  outOfRange = 'outOfRange',
  unavailable = 'unavailable',
}

export enum TrafficDirection {
  allTrafficDirections = 'allTrafficDirections',
  upstreamTraffic = 'upstreamTraffic',
  downstreamTraffic = 'downstreamTraffic',
  //oppositeTraffic = 'oppositeTraffic',
}

export enum StationType {
  //unknown = 0,
  //pedestrian = 1,
  cyclist = 2,
  //moped = 3,
  motorcycle = 4,
  passengerCar = 5,
  bus = 6,
  lightTruck = 7,
  heavyTruck = 8,
  //trailer = 9,
  specialVehicle = 10,
  //tram = 11,
  //lightVruVehicle = 12,
  //animal = 13,
  roadSideUnit = 15,
}

export type InformationQuality = 1 | 2 | 3 | 4 | 5 | 6;
export const informationQualityOptions = [1, 2, 3, 4, 5, 6];

export interface ActionId {
  originatingStationID: number;
  sequenceNumber: number;
}

export interface DENMOptional {
  management?: {
    altitude: {
      altitudeValue: number;
      altitudeConfidence: AltitudeConfidence;
    };
    relevanceTrafficDirection: TrafficDirection;
    stationType: StationType;
  };
  situation?: {
    informationQuality: InformationQuality;
    linkedCause: {
      causeCode: number;
      subCauseCode: number;
    };
  };
  location?: {
    speedValue: number;
    headingValue: number;
  };
  alacarte?: {
    roadWorks: {
      closedLanes: number;
      referenceDenms: ActionId[];
    };
  };
}
export interface CreateDENMPayload {
  subCauseId: string;
  validityPeriod: number;
  broadcastingFrequency: number;
  traceZoneCoordinates?: GeoJsonMultiLineString;
  historyZoneCoordinates: GeoJson;
  referencePoint: GeoJson;
  roadSegmentId: string;
  optional?: DENMOptional;
}

export interface UpdateDENMPayload {
  id: string;
  denmPayload: CreateDENMPayload;
}

export interface DisableDENMPayload {
  ids: string[];
  disable: boolean;
}

export interface DisableIVIMPayload {
  ids: string[];
  disable: boolean;
}

export interface GetDENMListPaylaod {
  roadSegmentIds: string[];
  date?: string;
  sources?: DenmSourceFilter[];
  subCauseId?: string;
  causeId?: string;
  createdFrom?: Date;
  createdTo?: Date;
  sincroDispatchable?: boolean;
  getAllData?: boolean;
  status?: DenmStatusFilter | '';
  excludeCauseAndSubcause?: boolean;
  currentPage?: number;
  itemsPerPage?: number;
}

export interface GetDENMPayload {
  id: string;
}

export interface CreateIVIMPayload {
  sequenceNumber: number;
  iviType: number;
  serviceCategoryCode: string;
  serviceSubCategoryCode: string;
  pictogramNature: number;
  pictogramSerialNumber: number;
  language: string;
  text: string[];
  countryCode: string;
  providerIdentifier: string;
  direction: number;
  starts: string;
  ends: string;
  relZoneCoordinates: GeoJson;
  detZoneCoordinates: GeoJson;
  referencePoint: GeoJson;
  roadSegmentId: string;
  broadcastingFrequency: number;
}

export interface UpdateIVIMPayload {
  id: string;
  ivimPayload: CreateIVIMPayload;
}

export interface GetIVIMListPaylaod {
  roadSegmentIds: string[];
  status: IvimStatusFilter | '';
  date?: string;
  createdFrom?: Date;
  createdTo?: Date;
  currentPage?: number;
  itemsPerPage?: number;
  serviceCategoryCode?: string;
  serviceSubCategoryCode?: string;
}

export interface DiagnosticData {
  idRSU: string;
  statusserviceUDP: number;
  statusharddisk: number;
  creationdatenum: number;
  updated: Date;
}

export interface SettingsPayload {
  distribution?: {
    denm: {
      source: DenmSourceFilter;
      value: boolean;
    };
  };
  storage?: {
    cam: {
      obu?: Obu[];
      obuFilterEnabled?: boolean;
      simulatorToggle?: boolean;
    };
  };
}

export type Obu = {
  name: string;
  id: string;
};
