import { useLocalization } from '@fluent/react';
import { Box, CardContent, Typography } from '@material-ui/core';
import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { events } from '../../../../components/icons/utils/cam';
import { camsSelector } from '_store/vehicles/selectors';
import styles from './styles';
import { settingsSelector } from '_store/settings/selectors';

const CamListLayout: FC = () => {
  const { l10n } = useLocalization();
  const classes = styles();

  const cams = useSelector(camsSelector);
  const camSettings = useSelector(settingsSelector).storage.cam;
  const obusFilteredEnabled = camSettings.obuFilterEnabled;
  const obus = camSettings.obu;

  const obuMap = useMemo(() => {
    if (!obusFilteredEnabled) {
      return new Map();
    }
    const map = new Map();
    obus.forEach((obu) => {
      map.set(obu.id, obu.name);
    });
    return map;
  }, [obus]);

  const camsFiltered = useMemo(() => {
    return cams.map((cam) => {
      const obuName = obuMap.get(cam.stationId) || 'Unknown';
      return { ...cam, obuName };
    });
  }, [cams, obuMap]);

  return (
    <CardContent className={classes.cardContent} style={{ overflow: 'hidden' }}>
      <div style={{ overflow: 'auto' }}>
        {camsFiltered &&
          camsFiltered.map((cam, index) => {
            const stationTypeText = events[cam.stationType]?.text || 'unknown';
            const speedValueConverted = cam.speedValue * 0.036;
            return (
              <Box
                display={'flex'}
                flexDirection={'column'}
                className={classes.boxContent}
                key={index}
              >
                <Typography variant="body2">
                  <strong>OBU</strong>: {cam.obuName}
                </Typography>
                <Typography variant="body2">
                  <strong>{l10n.getString('forms-info-box-station-id')}</strong>: {cam.stationId}
                </Typography>
                <Typography variant="body2">
                  <strong>{l10n.getString('forms-info-box-station-type')}</strong>:{' '}
                  {l10n.getString(`types-stationTypes-${stationTypeText}`)}
                </Typography>
                <Typography variant="body2">
                  <strong>{l10n.getString('forms-info-box-speed')}</strong>:
                  {l10n.getString('forms-info-box-speed-fmt', { speed: speedValueConverted })}
                </Typography>
                {cam.vehicleRole && (
                  <Typography variant="body2">
                    <strong>{l10n.getString('forms-info-box-vehicle-role')}</strong>:{' '}
                    {cam.vehicleRole}
                  </Typography>
                )}
              </Box>
            );
          })}
        {camsFiltered.length === 0 && l10n.getString('forms-cam-list-empty')}
      </div>
    </CardContent>
  );
};

export default React.memo(CamListLayout);
