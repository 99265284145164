import { createSelector } from '@reduxjs/toolkit';

import { getIsoDate } from '_store/utils';
import { RootState } from '..';
import { denmsAdapter } from './denmsReducers';
import { DenmDateFilterType, DenmSourceFilter, DenmStatusFilter } from './denmsTypes';
import { denmStatuses } from './utils';

const denmStateDomain = (state: RootState) => state.denms;

export const subscriptionIdSelector = createSelector(
  denmStateDomain,
  (denm) => denm.subscriptionId,
);

export const loadingSelector = createSelector(denmStateDomain, (denm) => denm.loading);

export const loadingCausesSelector = createSelector(denmStateDomain, (denm) => denm.loadingCauses);

export const loadingCSVSelector = createSelector(denmStateDomain, (denm) => denm.loadingCSV);

export const totalDenmsSelector = createSelector(denmStateDomain, (denm) => denm.total);

export const causesSelector = createSelector(denmStateDomain, (denm) => denm.causes);

export const paginationCurrentPageSelector = createSelector(
  denmStateDomain,
  (denm) => denm.paginationCurrentPage,
);

export const loadingSubCausesSelector = createSelector(
  denmStateDomain,
  (denm) => denm.loadingSubCauses,
);

export const loadingSubCausesByCauseSelector = createSelector(
  denmStateDomain,
  (denm) => denm.loadingSubCausesByCause,
);

export const subCausesSelector = createSelector(denmStateDomain, (denm) => denm.subCauses);

export const subCausesByCauseSelector = createSelector(
  denmStateDomain,
  (denm) => denm.subCausesByCause,
);

export const subCausesByCauseOptionalSelector = createSelector(
  denmStateDomain,
  (denm) => denm.optionalSubCausesByCause,
);

export const denmsSelector = createSelector(denmStateDomain, (denm) => {
  const allDenms = denmsAdapter.getSelectors().selectAll(denm);
  return allDenms
    .slice()
    .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
});

export const denmsFilteredSelector = createSelector(
  denmsSelector,
  denmStateDomain,
  (denms, denm) => {
    if (denms.length === 1 && denm.showDenmFilteredById) {
      return denms;
    }

    return denms
      .filter((d) => {
        const { isDenmActive, disabled } = denmStatuses(d);
        const statusActions = {
          [DenmStatusFilter.IN_PROGRESS]: isDenmActive,
          [DenmStatusFilter.CLOSED]: !isDenmActive,
          [DenmStatusFilter.DISABLED]: disabled,
        };
        const statusFilterResult = statusActions.hasOwnProperty(denm.statusFilter)
          ? statusActions[denm.statusFilter]
          : true;

        const causeFilterResult = (() => {
          if (!denm.causeFilter) {
            return true;
          }

          if (denm.excludeCauseAndSubcauseFilter) {
            return d.causeId !== denm.causeFilter;
          }

          return d.causeId === denm.causeFilter;
        })();

        const subCauseFilterResult = (() => {
          if (!denm.subCauseFilter) {
            return true;
          }

          if (denm.excludeCauseAndSubcauseFilter) {
            return d.subCauseId !== denm.subCauseFilter;
          }

          return d.subCauseId === denm.subCauseFilter;
        })();

        return (
          denm.sourceFilter.includes(d.source) &&
          (denm.dateFilterType !== DenmDateFilterType.RELATIVE ||
            d.createdAt >= getIsoDate(denm.dateFilter)) &&
          (denm.dateFilterType !== DenmDateFilterType.ABSOLUTE ||
            (new Date(d.createdAt) >= denm.createdFromFilter &&
              new Date(d.createdAt) <= denm.createdToFilter)) &&
          statusFilterResult &&
          subCauseFilterResult &&
          causeFilterResult
        );
      })
      .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
  },
);

export const denmsAllData = createSelector(denmStateDomain, (denm) => denm.allDenmsData);

export const denmFilterStatusSelector = createSelector(
  denmStateDomain,
  (denm) => denm.statusFilter,
);

export const denmFilterSourceSelector = createSelector(
  denmStateDomain,
  (denm) => denm.sourceFilter,
);

export const denmFilterDateSelector = createSelector(denmStateDomain, (denm) => denm.dateFilter);

export const denmFilterSubCauseSelector = createSelector(
  denmStateDomain,
  (denm) => denm.subCauseFilter,
);

export const denmFilterCauseSelector = createSelector(denmStateDomain, (denm) => denm.causeFilter);

export const denmFilterCreatedFromSelector = createSelector(
  denmStateDomain,
  (denm) => denm.createdFromFilter,
);

export const denmFilterCreatedToSelector = createSelector(
  denmStateDomain,
  (denm) => denm.createdToFilter,
);

export const denmFilterSincroDisaptchableSelector = createSelector(
  denmStateDomain,
  (denm) => denm.sincroDispatchableFilter,
);

export const denmFilterExcludeCauseAndSubcauseSelector = createSelector(
  denmStateDomain,
  (denm) => denm.excludeCauseAndSubcauseFilter,
);

export const denmsSelectorForSelectedRoadSegment = (roadSegmentIds: string[]) =>
  createSelector(denmsFilteredSelector, (denm) =>
    denm.filter((d) =>
      d.source === DenmSourceFilter.EMERAS ? roadSegmentIds.includes(d.roadSegmentId) : d,
    ),
  );

export const denmCauseRoadWorksSelector = createSelector(
  denmStateDomain,
  (denm) => denm.causes.find((event) => event.description === 'Road Works')?.id,
);
